<template>
  <button class="main_app_button" @click="handleClick">
    <img src="@/assets/new-theme/icons/plus.png" alt="icon button" />
    <span>{{ text }}</span>
  </button>
</template>

<script>
export default {
  name: "AppButton",
  props: {
    text: {
      type: String,
      default: "Add",
      required: false
    }
  },
  methods: {
    handleClick() {
      this.$emit("click");
    }
  }
};
</script>

<style lang="scss" scoped>
.main_app_button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: #7f56d9;
  border-radius: 8px;
  padding: 12px 20px;
  border: 1px solid #7f56d9;
  color: #fff;
  width: 200px;
}
</style>
