/*!

=========================================================
* Vue Light Bootstrap Dashboard - v1.4.0
=========================================================

* Product Page: http://www.creative-tim.com/product/vue-light-bootstrap-dashboard-pro
* Copyright 2019 Creative Tim (http://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard/blob/master/LICENSE.md)

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from "vue";
import LightBootstrap from "./light-bootstrap-main";
// Plugins
import App from "./App.vue";

global.jQuery = require("jquery");
var $ = global.jQuery;
window.$ = $;

import "./assets/sass/custom_styles.scss";

// router setup
import router from "./routes/index";
import store from "./store/store";
import i18n from "./i18n";
import "./mixins/mixins";
// plugin setup
Vue.use(LightBootstrap);

// configure router
import {
  Pagination,
  Dialog,
  Autocomplete,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Input,
  InputNumber,
  Radio,
  RadioGroup,
  RadioButton,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Switch,
  Select,
  Option,
  OptionGroup,
  Button,
  ButtonGroup,
  Table,
  TableColumn,
  DatePicker,
  TimeSelect,
  TimePicker,
  Popover,
  Tooltip,
  Breadcrumb,
  BreadcrumbItem,
  Form,
  FormItem,
  Tabs,
  TabPane,
  Tag,
  Tree,
  Alert,
  Slider,
  Icon,
  Row,
  Col,
  Upload,
  Progress,
  Spinner,
  Badge,
  Card,
  Rate,
  Steps,
  Step,
  Carousel,
  CarouselItem,
  Collapse,
  CollapseItem,
  Cascader,
  ColorPicker,
  Transfer,
  Container,
  Header,
  Aside,
  Main,
  Footer,
  Timeline,
  TimelineItem,
  Link,
  Divider,
  Image,
  Calendar,
  Backtop,
  PageHeader,
  CascaderPanel,
  Loading,
  MessageBox,
  Message,
  Notification
} from "element-ui";

import VueSocialSharing from "vue-social-sharing";

Vue.use(VueSocialSharing);

Vue.use(Pagination);
Vue.use(Dialog);
Vue.use(Autocomplete);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Checkbox);
Vue.use(CheckboxButton);
Vue.use(CheckboxGroup);
Vue.use(Switch);
Vue.use(Select);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(Button);
Vue.use(ButtonGroup);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(DatePicker);
Vue.use(TimeSelect);
Vue.use(TimePicker);
Vue.use(Popover);
Vue.use(Tooltip);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Tag);
Vue.use(Tree);
Vue.use(Alert);
Vue.use(Slider);
Vue.use(Icon);
Vue.use(Row);
Vue.use(Col);
Vue.use(Upload);
Vue.use(Progress);
Vue.use(Spinner);
Vue.use(Badge);
Vue.use(Card);
Vue.use(Rate);
Vue.use(Steps);
Vue.use(Step);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Cascader);
Vue.use(ColorPicker);
Vue.use(Transfer);
Vue.use(Container);
Vue.use(Header);
Vue.use(Aside);
Vue.use(Main);
Vue.use(Footer);
Vue.use(Timeline);
Vue.use(TimelineItem);
Vue.use(Link);
Vue.use(Divider);
Vue.use(Image);
Vue.use(Calendar);
Vue.use(Backtop);
Vue.use(PageHeader);
Vue.use(CascaderPanel);

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

import VueTour from "vue-tour";
require("vue-tour/dist/vue-tour.css");

Vue.use(VueTour);

Vue.use(Loading.directive);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
//

import GAuth from "vue-google-oauth2";
const gauthOption = {
  //// normal
  // clientId: '768834812579-007e5802er7gj3c93p8qa9568h8bj3na.apps.googleusercontent.com',

  // clientId: "268257316456-s37ddlhfcmj1sugj7pd0m58ps6b0sd83.apps.googleusercontent.com",
  // "clientSecret": "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",
  // scope: 'email',
  // prompt: 'select_account',
  //scope: 'profile email',

  // clientId:"744746699790-c5vo6umdtio43ps89fbd4hvq1u0fkaa2.apps.googleusercontent.com",
  // clientId:"268257316456-5pdbhav1jjg9153f58obe2ks0jtrpl2v.apps.googleusercontent.com",
  clientId:
    "268257316456-6cpoclgu2htsqmalpfus2b31sclrm814.apps.googleusercontent.com",
  scope: " email",
  prompt: "select_account",
  plugin_name: "plc-app-334615"
  // fetch_basic_profile: false
};
Vue.use(GAuth, gauthOption);

import ReuseableComponents from "@/components/a-new-theme/shared/ReuseableComponents";
ReuseableComponents.forEach(component => {
  Vue.component(component.name, component);
});

/* eslint-disable no-new */
new Vue({
  el: "#app",
  i18n,
  store,
  render: h => h(App),
  router
});
