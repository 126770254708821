<template>
  <div class="wrapper_custom_input">
    <span class="text new_theme_input_label">{{ text }}</span>
    <span class="star" v-if="star">*</span>
  </div>
</template>

<script>
export default {
  name: "AppInputLabel",
  data: {},
  props: {
    star: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: "",
      required: false
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper_custom_input {
  display: flex;
  align-items: center;
  .star {
    color: #fb404b;
    padding: 0 3px;
  }
}
</style>
