<template>
  <div>
    <div
      class="head_table padding d-flex align-items-center justify-content-between"
    >
      <div>
        <h2 class="main_title">
          {{ title }} <span class="total">{{ total }}</span>
        </h2>
        <p class="mb-0 text">
          {{ text }}
        </p>
      </div>
      <AppButton
        @click="ToggleTaskModal"
        :text="buttonText"
        v-if="buttonText"
      ></AppButton>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableHeaderSection",
  props: {
    // toggleModal: Function,
    title: {
      type: String,
      default: ""
    },
    text: {
      type: String,
      default: ""
    },
    buttonText: {
      type: String,
      default: ""
    },
    total: {
      type: [String, Number],
      default: "",
      required: false
    }
  },
  methods: {
    ToggleTaskModal() {
      this.$emit("toggleModal");
    }
  }
};
</script>

<style lang="scss" scoped>
.head_table {
  border-bottom: 1px solid #eaecf0;
}

.main_title {
  color: #101828;
  font-weight: 600;
  font-size: 20px;
}
.padding {
  padding: 14px;
}
.text {
  color: #667085;
  font-weight: 400;
  font-size: 16px;
}
</style>
